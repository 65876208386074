<template>
  <div class="orderlist">
    <!--     <div class="search">
      <div class="searchinput">
        <div class="searchfile">
          <div class="shuru">
            <label>订单号：</label
            ><el-input
              v-model="form.orderNo"
              placeholder="请输入内容"
              size="mini"
            ></el-input>
          </div>
          <div class="shuru">
            <label>起运港：</label
            ><el-input
              v-model="form.startShipment"
              placeholder="请输入内容"
              size="mini"
            ></el-input>
          </div>
          <div class="shuru">
            <label>目的港：</label
            ><el-input
              v-model="form.destinationPort"
              placeholder="请输入内容"
              size="mini"
            ></el-input>
          </div>
        </div>
        <div class="searchfile">
          <div class="shuru">
            <label>集货仓库：</label
            ><el-input
              v-model="form.destinationPort"
              placeholder="请输入内容"
              size="mini"
            ></el-input>
          </div>
          <div class="shuru">
            <label>目的仓库：</label
            ><el-input
              v-model="form.destinationWarehouseCode"
              placeholder="请输入内容"
              size="mini"
            ></el-input>
          </div>
          <div class="">
            <el-button
              type="primary"
              round
              icon="el-icon-search"
              size="mini"
              @click="getorderlisetinfo"
              >查询</el-button
            >
            <el-button type="success" round icon="el-icon-upload" size="mini"
              >导出</el-button
            >
          </div>
        </div>
      </div>
    </div> -->
    <div class="c-box">
      <el-collapse v-model="activeName" style="padding-top: 10px">
        <el-collapse-item :name="1">
          <el-row style="margin-bottom: 10px">
            <el-col :span="6">
              <label class="searchType">订单号：</label>
              <el-input
                v-model="form.orderNo"
                class="searchContent"
                size="mini"
                clearable
                placeholder="请输入"
              />
            </el-col>
            <!-- <el-col :span="6">
              <label class="searchType">订单状态：</label>
              <el-select
                v-model="frontStatusCode"
                class="searchContent"
                size="mini"
                clearable
                filterable
              >
                <el-option
                  v-for="item in OrderStatus.frontStatus"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-col> -->
            <el-col :span="6">
              <label class="searchType">开船日期：</label>
              <el-date-picker
                v-model="form.sailTime"
                class="searchContent"
                size="mini"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                clearable
                placeholder="请选择"
              />
            </el-col>
            <el-col :span="6">
              <label class="searchType">服务名称：</label>
              <el-select
                v-model="form.sid"
                class="searchContent"
                size="mini"
                clearable
                filterable
              >
                <el-option
                  v-for="item in ServiceGroup"
                  :key="item.id"
                  :label="item.codeName"
                  :value="item.id"
                />
              </el-select>
            </el-col>
            <el-col :span="6">
              <label class="searchType">运输方式：</label>
              <el-select
                v-model="form.tid"
                class="searchContent"
                size="mini"
                clearable
                filterable
              >
                <el-option
                  v-for="item in TransportWay"
                  :key="item.id"
                  :label="item.codeName"
                  :value="item.id"
                />
              </el-select>
            </el-col>
          </el-row>
          <el-row style="margin-bottom: 10px">
            <el-col :span="6">
              <label class="searchType">目的地仓库代码：</label>
              <el-select
                v-model="form.destinationWarehouseCode"
                class="searchContent"
                size="mini"
                clearable
                filterable
              >
                <el-option
                  v-for="item in fabWarehouse"
                  :key="item.id"
                  :label="item.warehouseName"
                  :value="item.warehouseCode"
                />
              </el-select>
            </el-col>
            <el-col :span="6">
              <label class="searchType">唛头：</label>
              <el-input
                v-model="form.warehouseNo"
                class="searchContent"
                size="mini"
                clearable
              />
            </el-col>
            <el-col :span="6">
              <label class="searchType">FBA号：</label>
              <el-input
                v-model="form.fabNo"
                class="searchContent"
                size="mini"
                clearable
              />
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <label class="searchType" />
              <el-button
                type="primary"
                size="mini"
                class="btnGroup btn-search"
                @click="CheckOrder"
              >搜索</el-button>
              <el-button
                type="primary"
                size="mini"
                class="btnGroup"
                @click="clearSearchType"
              >清空</el-button>
              <el-button
                type="primary"
                size="mini"
                class="btnGroup"
                style="width: 130px"
                @click="batchDownload(1)"
              >批量下载箱唛</el-button>
              <el-button
                type="primary"
                size="mini"
                class="btnGroup"
                style="width: 150px"
                @click="batchDownload(2)"
              >批量下载进仓单</el-button>
              <el-button
                type="primary"
                size="mini"
                class="btnGroup"
                style="width: 150px"
                @click="mergeDownload"
              >合并下载进仓单</el-button>
              <el-checkbox v-model="checkAll" style="margin-left: 20px" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
            </el-col>
          </el-row>
        </el-collapse-item>
      </el-collapse>
      <div class="listqie">
        <div
          class="anniu"
          :class="quanbu == '1' ? 'action' : ''"
          @click="qiehuan('')"
        >
          全部
        </div>
        <div
          class="anniu"
          :class="quanbu == '0' ? 'action' : ''"
          @click="qiehuan('0')"
        >
          草稿(<span>{{ draftNum.draftNum }}</span>)
        </div>
        <div
          class="anniu"
          :class="quanbu == '9' ? 'action' : ''"
          @click="qiehuan('9')"
        >
          补资料(<span>{{ draftNum.updateNum }}</span>)
        </div>
        <div
          class="anniu"
          :class="quanbu == '10' ? 'action' : ''"
          @click="qiehuan('10')"
        >
          已下单(<span>{{ draftNum.placedNum }}</span>)
        </div>
        <el-tooltip
          class="item"
          effect="dark"
          content="请确认计费重"
          placement="top-start"
        >
          <div
            class="anniu"
            :class="quanbu == '20' ? 'action' : ''"
            @click="qiehuan('20')"
          >
            已收货(<span>{{ draftNum.receivedNum }}</span>)
          </div>
        </el-tooltip>
        <div
          class="anniu"
          :class="quanbu == '30' ? 'action' : ''"
          @click="qiehuan('30')"
        >
          转运中(<span>{{ draftNum.transitNum }}</span>)
        </div>
        <div
          class="anniu"
          :class="quanbu == '40' ? 'action' : ''"
          @click="qiehuan('40')"
        >
          已签收(<span>{{ draftNum.signedNum }}</span>)
        </div>
        <div
          class="anniu"
          :class="quanbu == '50' ? 'action' : ''"
          @click="qiehuan('50')"
        >
          已完成(<span>{{ draftNum.finishNum }}</span>)
        </div>
        <div
          class="anniu"
          :class="quanbu == '-1' ? 'action' : ''"
          @click="qiehuan('-1')"
        >
          已取消(<span>{{ draftNum.cancelNum }}</span>)
        </div>
      </div>
      <div v-loading="listLoading" style="min-height: 180px">
        <el-checkbox-group v-model="checkList">
          <div v-for="obj in listdata" :key="obj.id" class="listdata">
            <div class="dateinfo">
              <div style="width: 50px; flex: unset">
                <el-checkbox :label="obj.id + '|' + obj.orderNo">{{ '' }}</el-checkbox>
              </div>
              <div class="nth-div">
                <span class="dian">●</span>订单号：<span>{{ obj.orderNo }}</span>
              </div>
              <div>
                <span class="dian">●</span>订单状态：<span>{{
                  obj.frontStatusName
                }}</span>
              </div>
              <div v-if="obj.isPick">
                <el-popover placement="top-start" trigger="hover">
                  <div>
                    <p v-for="(item, i) in obj.pickStatusVOList" :key="i">
                      {{ item.warehouseNo }} {{ item.pickStatusDescribes }}
                    </p>
                  </div>
                  <el-button
                    slot="reference"
                  ><span class="dian">●</span>提货状态</el-button>
                </el-popover>
              </div>
              <div class="nth-div">
                <span class="dian">●</span>开船日期：<span>{{ obj.sailTime }}</span>
              </div>
              <div class="nth-div">
                <span class="dian">●</span>创建时间：<span>{{
                  obj.createTime
                }}</span>
              </div>
              <!--           <div>
                <p class="buttom" @click="addWorkOrder(obj.id)">创建工单</p>
              </div> -->
            </div>
            <div class="luxian">
              <div class="leftdiv">
                <div class="guojia">{{ obj.names }}</div>
              </div>
              <div class="zhongdiv">
                <div class="dizhi">
                  <div class="chengshi">{{ obj.startShipmentCityName }}</div>
                  <div class="changku">{{ obj.storeGoodsWarehouseName }}</div>
                </div>
                <div class="huoshu">
                  <div class="xiangshu">发货数：{{ obj.quantityShipped }}箱</div>
                  <div class="jiantou"><span>▸</span></div>
                </div>
                <div class="dizhi">
                  <div class="chengshi">{{ obj.destinationWarehouseCode }}</div>
                  <div class="changku">{{ obj.destinationWarehouseName }}</div>
                </div>
              </div>
              <div class="rithgdiv">
                <div class="caozuo">
                  <div
                    v-if="obj.frontStatusCode == '9'"
                    class="buttondiv eidtdiv"
                    @click="eidtorderinfoEnterGoOrder(obj.id, obj.offerInfoId)"
                  >
                    编辑
                  </div>
                  <div
                    v-if="obj.frontStatusCode == '9'"
                    class="buttondiv changediv"
                    @click="openBj(obj.id, obj.offerInfoId)"
                  >
                    替换报价
                  </div>
                  <div
                    v-if="
                      obj.frontStatusCode === '20' && obj.isConfirmBilling === '2'
                    "
                    class="detailsdiv"
                    style="line-height: 25px"
                    @click="orderdetailsinfo(obj.id)"
                  >
                    请确认计费重
                  </div>
                  <!-- <div
                    class="buttondiv eidtdiv"
                    v-if="obj.frontStatusCode == '30'"
                    @click="eidtorderinfo(obj.id)"
                  >
                    确认计柜重
                  </div> -->
                  <div
                    v-if="false"
                    class="buttondiv yiqueren"
                    @click="eidtorderinfo(obj.id)"
                  >
                    账单确认
                  </div>
                </div>
                <div
                  style="display: flex; justify-content: center; margin-top: 5px"
                >
                  <div
                    v-if="obj.frontStatusCode === '0'"
                    class="detailsdiv commitBtn"
                    style="line-height: 25px"
                    @click="dingdantijiao(obj.id, obj.offerInfoId)"
                  >
                    编辑
                  </div>
                  <div
                    v-if="obj.frontStatusCode == '0'"
                    class="detailsdiv cancelBtn"
                    style="line-height: 25px"
                    @click="quxiaodingdan(obj.id)"
                  >
                    取消
                  </div>
                </div>
                <div
                  v-if="obj.frontStatusCode != '9'"
                  class="detailsdiv"
                  style="line-height: 25px"
                  @click="orderdetailsinfo(obj.id)"
                >
                  查看订单详情
                </div>
              </div>
            </div>
            <div style="display: flex">
              <div class="beizhu">备注：{{ obj.remark }}</div>
              <div class="beizhu">唛头：{{ obj.warehouseNo }}</div>
              <div class="beizhu">FBA单号：{{ obj.extensionNumberCase }}</div>
              <div class="beizhu">转单号：
                <!-- <a :href="'https://www.ups.com/track?loc=zh_CN&track.x=Track&trackNums=' + obj.trackingNumber + '&requester=ST/trackdetails'" target="_blank">{{ obj.trackingNumber }}</a> -->
                <a
                  v-if="/ups/ig.test(obj.carrierCode)"
                  :href="'https://www.ups.com/track?loc=zh_CN&track.x=Track&trackNums=' + obj.trackingNumber + '&requester=ST/trackdetails'"
                  target="_blank"
                >
                  {{ obj.trackingNumber }}
                </a>
                <a
                  v-else-if="/fedex/ig.test(obj.carrierCode)"
                  :href="'https://www.fedex.com/fedextrack/?action=track&trackingnumber=' + obj.trackingNumber + '&cntry_code=cn&locale=en_cn'"
                  target="_blank"
                >
                  {{ obj.trackingNumber }}
                </a>
                <span v-else>{{ obj.trackingNumber }}</span>
              </div>
            </div>
          </div>
        </el-checkbox-group>
      </div>

      <!-- 替换报价弹框 -->
      <el-dialog
        title="替换报价"
        :visible.sync="dialogVisible"
        width="70%"
      >
        <div>
          <FreightRateCy :id="objid" :o-id="replaceOfferId" />
        </div>
      </el-dialog>

      <el-pagination
        class="fenye"
        background
        layout="total, sizes, prev, pager, next"
        :current-page="form.pageNum"
        :page-size="form.pageSize"
        :page-sizes="[10, 20, 30, 40]"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />

      <el-dialog title="下载进度" :visible.sync="downVisible" width="400px" destroy-on-close :before-close="closeDvisi">
        <el-progress :text-inside="true" :stroke-width="24" :percentage="percentage" status="success" />
        <div style="margin: 10px 0">
          <span>成功<span style="color: #67c23a">{{ processSuccess }}</span>条，</span>
          <span>失败<span style="color: #f56c6c">{{ processFail }}</span>条</span>
        </div>
        <div style="color: #f56c6c">
          {{ failDataList.join('，') }}
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  orderlistdata,
  draftCancelOrderInfo,
  findOrderStatus,
  findServiceGroup,
  findTransportWay,
  findfabWarehouse,
  batchDownloadPdfWarehouseNoByOrderId1,
  batchDownloadPdfWarehouseNoByOrderId2,
  downloadWarehouseNoByOrderIds
} from '@/api/order.js';
import FreightRateCy from './FreightRateCy.vue';
import { saveAs } from 'file-saver';
export default {
  name: 'Orderlist',
  components: {
    FreightRateCy: FreightRateCy
  },
  props: {},
  data: function() {
    return {
      // 全选按钮
      checkAll: false,
      checkList: [],
      isIndeterminate: false,

      downVisible: false,
      dialogVisible: false,
      tableData: [],
      search: {},
      draftNum: [],
      total: 0,
      listdata: [],
      listLoading: false,
      quanbu: '1',
      form: {
        customerId: '',
        orderNo: '',
        destinationWarehouseCode: '',
        frontStatusCode: '',
        startShipment: '',
        destinationPort: '',
        sailTime: '',
        pageNum: 1,
        pageSize: 10
      },
      frontStatusCode: '',
      OrderStatus: [],
      ServiceGroup: [],
      TransportWay: [],
      fabWarehouse: [],
      activeName: 1,
      objid: '',
      replaceOfferId: '',

      // 下载框数据
      processTotal: 0,
      processCurrent: 0,
      processSuccess: 0,
      processFail: 0,
      failDataList: []
    };
  },
  computed: {
    percentage() {
      return (Math.round((this.processCurrent / this.processTotal * 100) * 100) / 100) || 0;
    },
    cityOptions() {
      return this.listdata.map(item => (item.id + '|' + item.orderNo));
    }
  },
  watch: {
    cityOptions(val) {
      // 有数据
      if (val.length) {
        // 每个数据都勾选了吗
        const flag = val.every(item => this.checkList.indexOf(item) > -1);
        const flag2 = val.some(item => this.checkList.indexOf(item) > -1);
        this.checkAll = flag;
        this.isIndeterminate = !flag && flag2;
      } else {
        this.checkAll = false;
        this.isIndeterminate = false;
      }
    },
    checkList(val) {
      // 有数据
      if (this.cityOptions.length) {
        // 每个数据都勾选了吗
        const flag = this.cityOptions.every(item => val.indexOf(item) > -1);
        const flag2 = this.cityOptions.some(item => val.indexOf(item) > -1);
        this.checkAll = flag;
        this.isIndeterminate = !flag && flag2;
      } else {
        this.checkAll = false;
        this.isIndeterminate = false;
      }
    }
  },
  created() {
    this.getorderlisetinfo();
    this.initSearchTerms();
  },
  mounted() {},
  methods: {
    // 全选按钮change事件
    handleCheckAllChange(val) {
      if (val) {
        this.checkList = [...this.checkList, ...this.cityOptions];
      } else {
        this.checkList = this.checkList.filter(item => this.cityOptions.indexOf(item) === -1);
      }
      this.isIndeterminate = false;
    },
    openBj(objid, replaceOfferId) {
      this.replaceOfferId = replaceOfferId;
      this.objid = objid;
      this.dialogVisible = true;
    },
    initSearchTerms() {
      this.findOrderStatus();
      this.findServiceGroup();
      this.findTransportWay();
      this.findfabWarehouse();
    },
    findOrderStatus() {
      findOrderStatus().then((res) => {
        if (+res.data.code === 0) {
          this.OrderStatus = res.data.data;
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });
        }
      });
    },
    findServiceGroup() {
      findServiceGroup().then((res) => {
        if (+res.data.code === 0) {
          this.ServiceGroup = res.data.data;
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });
        }
      });
    },
    findTransportWay() {
      findTransportWay().then((res) => {
        if (+res.data.code === 0) {
          this.TransportWay = res.data.data;
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });
        }
      });
    },
    findfabWarehouse() {
      findfabWarehouse().then((res) => {
        if (+res.data.code === 0) {
          this.fabWarehouse = res.data.data;
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });
        }
      });
    },
    handleCurrentChange(val) {
      // 切换页数
      this.form.pageNum = val;
      this.getorderlisetinfo();
    },
    handleSizeChange(val) {
      this.form.pageNum = 1;
      this.form.pageSize = val;
      this.getorderlisetinfo();
    },
    addWorkOrder(id) {
      // 前往创建工单
      this.$router.push({
        path: '/user/addWorkOrder',
        query: {
          id: id,
          type: 1
        }
      });
    },
    getorderlisetinfo() {
      this.listLoading = true;
      orderlistdata(this.form)
        .then((result) => {
          if (result.data.code == 0) {
            this.listdata = result.data.data.list;
            this.total = result.data.data.total;
            this.draftNum = result.data.data;
          } else {
            this.listdata = [];
            this.total = 0;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          this.listdata = [];
          this.total = 0;
          this.listLoading = false;
          try {
            if (error.response) {
              this.$message.error({
                message: error.response.data.msg
              });
            } else {
              this.$message.error({
                message: '请求失败'
              });
            }
          } catch (err) {
            this.$message.error({
              message: err
            });
          }
        });
    },
    qiehuan(val) {
      this.form.frontStatusCode = val;
      if (val == '') {
        this.quanbu = '1';
      } else {
        this.quanbu = val;
      }
      this.form.pageNum = 1;
      this.getorderlisetinfo();
    },
    orderdetailsinfo(id) {
      this.$router.push({
        name: 'orderdetails',
        query: {
          id: id
        }
      });
    },
    eidtorderinfo(id) {
      this.$router.push({
        name: 'editorder',
        query: {
          id: id
        }
      });
    },
    eidtorderinfoEnterGoOrder(id, oId) {
      this.$router.push({
        name: 'GoOrder',
        query: {
          id: id,
          typ: 1,
          edit: 1,
          oId
        }
      });
    },
    dingdantijiao(id, oId) {
      this.$router.push({
        name: 'GoOrder',
        query: {
          id: id,
          typ: 1,
          oId
        }
      });
    },
    quxiaodingdan(id) {
      draftCancelOrderInfo({
        id: id
      }).then((result) => {
        if (result.data.code == 0) {
          this.$message({
            message: '成功',
            duration: 1500,
            type: 'success'
          });
          this.getorderlisetinfo();
        }
      });
    },
    CheckOrder() {
      // this.form.frontStatusCode = this.frontStatusCode;
      // orderlistdata(this.form)
      // .then(res => {
      //   if(+res.data.code === 0) {
      //     this.listdata = res.data.data;
      //   }
      // })
      this.checkList = [];
      this.qiehuan('');
    },
    clearSearchType() {
      this.form = {
        customerId: '',
        orderNo: '',
        destinationWarehouseCode: '',
        frontStatusCode: '',
        startShipment: '',
        destinationPort: '',
        sailTime: '',
        pageNum: 1,
        pageSize: 10
      };
    },
    async batchDownload(downType) {
      if (!this.checkList.length) {
        this.$message.warning('请勾选要下载的订单');
        return false;
      }
      const checkList = this.checkList.map(item => item.split('|'));
      this.processTotal = checkList.length;
      this.downVisible = true;
      for (let i = 0; i < checkList.length; i++) {
        try {
          let res;
          if (downType === 1) {
            res = await batchDownloadPdfWarehouseNoByOrderId2({ id: checkList[i][0], noLoad: true });
          } else if (downType === 2) {
            res = await batchDownloadPdfWarehouseNoByOrderId1({ id: checkList[i][0], noLoad: true });
          }
          if (res.data.type !== 'application/json') {
            saveAs(res.data, decodeURI((res.headers['content-disposition'].split('='))[1]));
            this.processSuccess += 1;
          } else {
            // res.data.text().then(blobRes =>{
            //   this.$message.error(JSON.parse(blobRes).msg)
            // })
            this.processFail += 1;
            this.failDataList.push(checkList[i][1]);
          }
          this.processCurrent += 1;
        } catch (err) {
          this.processFail += 1;
          this.processCurrent += 1;
          this.failDataList.push(checkList[i][1]);
        }
      }
    },
    mergeDownload() {
      if (!this.checkList.length) {
        this.$message.warning('请勾选要下载的订单');
        return false;
      }
      const checkList = this.checkList.map(item => item.split('|'));
      const ids = checkList.map(item => +item[0]);
      window.open(downloadWarehouseNoByOrderIds(ids.toString()));
    },
    closeDvisi() {
      this.downVisible = false;
      this.processTotal = 0;
      this.processCurrent = 0;
      this.processSuccess = 0;
      this.processFail = 0;
      this.failDataList = [];
    }
  }
};
</script>

<style scoped>
.orderlist /deep/.el-dialog__headerbtn {
  position: absolute;
  top: 12px;
  right: 20px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}
.orderlist /deep/.el-dialog__header {
  padding: 10px 20px 10px;
  background: #f1f1f1;
}
.orderlist /deep/.el-dialog__body {
  padding: 20px !important;
}
.el-popper p {
  margin: 0;
}
.buttom {
  margin: 0;
  width: 80px;
  float: right;
  background: #507eff;
  border-radius: 0 10px 0 0;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
}
.orderlist {
  width: 100%;
  background-color: #f1f1f1;
}
.search {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  background-color: #fff;
}
.searchinput {
  width: 1200px;
  height: 100px;
  margin: 0 auto;
}
.searchfile {
  width: 100%;
  height: 50px;
  display: flex;
  line-height: 45px;
}
.shuru {
  width: 400px;
  display: flex;
}
.shuru label {
  width: 100px;
  text-align: right;
  display: block;
  /* font-size: 16px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #333333; */
}
.el-input {
  width: 200px;
}
.listqie {
  width: 100%;
  height: 36px;
  display: flex;
  margin-top: 10px;
}
.anniu {
  width: 100px;
  margin: 0 2px 2px 0;
  height: 34px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  /* box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.07); */
  border-radius: 6px 6px 0 0;
  font-weight: 400;
  color: #000;
  background: #fff;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
}
.action {
  color: #fff;
  background: #507eff;
}
.listdata {
  width: 100%;
  height: 181px;
  background: #ffffff;
  border-radius: 0 8px 8px 8px;
  margin-bottom: 10px;
}
.dateinfo {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #eeeeee;
}
.dateinfo > div {
  flex: 1;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
}
.dateinfo > div button {
  border: 0;
}
.dateinfo .nth-div {
  flex: 2;
}
.dateinfo .dian {
  margin-right: 6px;
  color: #507eff;
}
.luxian {
  width: 100%;
  height: 100px;
  display: flex;
  border-bottom: 1px solid #eeeeee;
}
.leftdiv {
  width: 30%;
  height: 70px;
  padding-top: 15px;
}
.zhongdiv {
  width: 40%;
  height: 100px;
  display: flex;
}
.leftdiv .guojia {
  width: 180px;
  white-space: nowrap;
  text-align: center;
  line-height: 70px;
  margin: 0 auto;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.dizhi {
  width: 33%;
  padding-top: 10px;
}
.chengshi {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  text-align: center;
  line-height: 45px;
}
.changku {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
}
.huoshu {
  width: 33%;
  padding-top: 20px;
}
.xiangshu {
  width: 124px;
  height: 32px;
  border: 1px solid #cccccc;
  border-radius: 9px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  text-align: center;
  line-height: 32px;
  margin: 0 auto;
}
.jiantou {
  width: 152px;
  height: 1px;
  background-color: #507eff;
  opacity: 0.9;
  position: relative;
  margin: 0 auto;
  margin-top: 10px;
}
.jiantou span {
  position: absolute;
  right: -14px;
  top: -17px;
  font-size: 24px;
  color: #507eff;
}
.buttondiv {
  position: relative;
  width: 120px;
  height: 36px;
  background: #fc6c3f;
  box-shadow: 0px 7px 6px 0px rgba(49, 49, 49, 0.07);
  border-radius: 18px;
  text-align: center;
  color: #fff;
  line-height: 36px;
  cursor: pointer;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin: 0 auto;
}
.eidtdiv {
  background: #54a0ff;
  height: 30px;
  line-height: 30px;
  width: 120px;
}
.changediv {
  background: #54a0ff;
  height: 30px;
  line-height: 30px;
  margin-top: 80px;
  position: absolute;
}
.yiqueren {
  background: #fc6c3f;
  color: #fff;
  height: 30px;
  line-height: 30px;
  width: 120px;
}
.detailsdiv {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #507eff;
  line-height: 45px;
  text-align: center;
  cursor: pointer;
}
.rithgdiv {
  margin-top: 5px;
  width: 30%;
  height: 70px;
}
.caozuo {
  display: flex;
  flex-wrap: nowrap;
  width: 200px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}
.beizhu {
  height: 40px;
  line-height: 40px;
  text-indent: 1em;
  margin-right: 20px;
}
.commitBtn {
  margin-right: 10px;
  width: 80px;
  height: 30px;
  background: #2aba35;
  box-shadow: 0px 7px 6px 0px rgba(49, 49, 49, 0.07);
  border-radius: 15px;
  color: #fff;
}
.cancelBtn {
  width: 80px;
  height: 30px;
  border: 1px solid #2aba35;
  border-radius: 15px;
  background-color: #fff;
  color: #2aba35;
}
.c-box {
  background: #f1f1f1;
}
.fenye {
  padding-bottom: 20px;
}
.searchType {
  display: inline-block;
  width: 104px;
  text-align: right;
}
.searchContent {
  width: 180px;
}
.btnGroup {
  width: 100px;
  font-size: 16px;
}
::v-deep .el-collapse-item__content {
  padding-bottom: 10px;
}
::v-deep .el-collapse-item__header {
  height: 30px;
  line-height: 30px;
}
::v-deep .el-checkbox-group {
  font-size: unset;
}
::v-deep .el-checkbox__inner {
  border: 2px solid #409EFF;
  width: 16px;
  height: 16px;
}
</style>
