<template>
  <div class="home">
    <div class="c-box">
      <div class="content">
        <span style="display: inline-block">服务名称：</span><el-input v-model="forData.names" clearable size="mini" style="width: 200px; margin: 0 10px 10px 0" @keyup.enter="fareByPage" />
        <el-button size="mini" type="primary" @click="fareByPage">查询</el-button>
        <el-table
          class="freightRate-table"
          :data="tableData"
          height="500"
          style="width: 100%"
        >
          <el-table-column
            align="center"
            prop="names"
            label="服务名称"
            width="180"
          >
            <template slot-scope="scope">
              <!-- <span class="goodname" @click="goto('/freightRate/serviceDetails?id='+scope.row.id)" v-if="scope.row.names">{{scope.row.names}}</span> -->
              <span v-if="scope.row.names">{{ scope.row.names }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="shipRoute"
            label="起运港/目的港"
            width="130"
          />
          <el-table-column
            align="center"
            width="60"
            label="方式"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.types==1">整柜</span>
              <span v-else>拼箱</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="shipRoute"
            label="目的仓库"
            width="120"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <!-- <div style="max-height:64px;overflow:auto;"> -->
              <span>{{ scope.row.arriveWarehouseCodes }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="sailTime"
            width="100"
            label="开船日期"
          />
          <el-table-column
            align="center"
            prop="cutOffTime"
            width="100"
            label="截单日期"
          />
          <el-table-column
            align="center"
            prop="voyageDay"
            width="60"
            label="航程"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.voyageDay">{{ scope.row.voyageDay }}天</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            width="180"
            label="区间"
          >
            <template slot-scope="scope">
              <div>
                <p v-for="(item,i) in scope.row.oceanFeeList" :key="i">
                  <span v-if="item">{{ item.specificationName }}</span>
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="address"
            width="50"
            label="金额"
          >
            <template slot-scope="scope">
              <p v-for="(item,i) in scope.row.oceanFeeList" :key="i">
                <span v-if="item">{{ item.unitPrice }}</span>
                <!-- <span v-if="item">{{item.cid}}</span> -->
              </p>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="address"
            width="50"
            label="单位"
          >
            <template slot-scope="scope">
              <p v-for="(item,i) in scope.row.oceanFeeList" :key="i">
                <span v-if="item" v-show="item.unit==1">KG</span>
                <span v-if="item" v-show="item.unit==2">票</span>
                <span v-if="item" v-show="item.unit==3">个</span>
                <span v-if="item" v-show="item.unit==4">柜</span>
                <span v-if="item" v-show="item.unit==5">方</span>
              </p>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="address"
            width="120px"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button type="warning" class="bhe36" @click="goto(scope.row.id)">替换报价</el-button>
            </template>

          </el-table-column>
        </el-table>
        <el-pagination
          class="fenye"
          background
          layout="prev, pager, next"
          :current-page="forData.pageNum"
          :page-size="forData.pageSize"
          :total="total"
          @current-change="handleCurrentChange"
        />
        <div style="height:50px" />
      </div>
    </div>
  </div>
</template>

<script>
import { findCountry, findTransportWay, findfabWarehouse, findQuotationTypeBy } from '@/api/select.js';
import { fareByPage } from '@/api/freight.js';
export default {
  name: 'FreightRateCy',
  props: ['id', 'oId'],
  data() {
    return {
      // 弹窗相关
      dialogVisible: false,
      offerInfoId: '',
      win_type: 1,
      Country: [],
      Freight: [],
      Warehouse: [],
      Types: [],
      forData: {/* types:'1',*/},
      total: 0,
      tableData: [],
      action: process.env.VUE_APP_BASEURL + '/jayudMallWeb/orderinfo/importExcelByNewWisdom',
      serviceName: ''
    };
  },
  computed: {

  },
  watch: {
    forData(val) {
      return this.forData.stateCode == val;
    }
  },
  created() {
    this.init();
    this.fareByPage();
  },
  mounted() {

  },
  methods: {
    init() {
      this.findCountry();
      this.findTransportWay();
      this.$forceUpdate();
      this.findfabWarehouse();
    },
    setCode(id, name) { // 选择国家 运输方式 柜型
      if (id == this.forData[name]) {
        this.forData[name] = null;
        this.forData = { ...this.forData };
      } else {
        this.forData[name] = id;
        this.forData = { ...this.forData };
      }
      this.fareByPage();
    },
    findCountry() { // 查询国家
      if (this.$store.state.country.length) {
        this.Country = this.$store.state.country;
      } else {
        findCountry().then(result => {
          if (result.status == 200) {
            const data = result.data;
            this.Country = data.data;
            // this.forData.stateCode=data.data[0].code
            this.$store.dispatch('newCountry', data.data);
          }
        });
      }
      findQuotationTypeBy().then(result => {
        if (result.status == 200) {
          this.Types = result.data.data;
        }
      });
    },
    findTransportWay() { // 查询运输方式
      if (this.$store.state.freight.length) {
        this.Freight = this.$store.state.freight;
      } else {
        findTransportWay().then(result => {
          if (result.status == 200) {
            const data = result.data;
            this.Freight = data.data;
            // this.forData.tid=data.data[0].idCode
            this.$store.dispatch('newFreight', data.data);
          }
        });
      }
    },
    findfabWarehouse() { // 查询目的仓库
      findfabWarehouse().then(result => {
        if (result.status == 200) {
          const data = result.data;
          this.Warehouse = data.data;
        }
      });
    },
    fareByPage() { // 查询分页数据
      fareByPage(this.forData).then(result => {
        if (result.data.code == 0) {
          const data = result.data;
          this.tableData = data.data.list;
          this.total = data.data.total;
        }
      }).catch(error => {
        try {
          if (error.response) {
            this.$message.error({
              message: error.response.data.msg
            });
          } else {
            this.$message.error({
              message: '请求失败'
            });
          }
        } catch (err) {
          this.$message.error({
            message: err
          });
        }
      });
    },
    handleCurrentChange(val) { // 切换页数
      this.forData.pageNum = val;
      this.fareByPage();
    },
    goto(id) {
      this.$router.push({
        path: '/freightRate/goOrder',
        query: {
          id: this.id,
          oId: id,
          typ: 1,
          isReplaceOfferInfo: true,
          replaceOfferInfoId: id
        }
      });
    },
    judgeType(file) {
      if (file.name.indexOf('.xlsx') == -1 && file.name.indexOf('.xls') == -1) {
        this.$message({
          message: "Uploading file's format is error, please check.",
          type: 'error',
          duration: 1000
        });
        return false;
      }
    },
    upload(id) { // 上传箱单
      this.offerInfoId = id;
      this.openModal();
    },
    openModal() { // 打开弹窗
      this.dialogVisible = true;
    },
    closeModal() { // 关闭弹窗
      this.dialogVisible = false;
    }
  }
};

</script>

<style scoped>
.title{height:30px;line-height:30px;background:#F0F0F0;margin:0;font-size:12px;color:#999999}
.title i{margin-right:10px;}
.title span{cursor:pointer;}
.title span:nth-child(3){color:#333}
.country{margin-top:50px;}
.button{
    display: inline-block;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 20px;}
.label{font-size:12px;display:inline-block;margin-right:20px}
.select{background:#FC6C3F;color:#fff;border-color:#FC6C3F !important;}
.transport{overflow:hidden;margin-top:44px}
.transport>div{width:50%;float:left;}
.content{margin-top:44px}
.goodname{border-bottom: 1px solid #008cd6;color: #008cd6;cursor:pointer;}
.type1{
  margin: 0;
  margin-top: 10px;
  border-radius: 30px;
  padding: 5px 10px;
  color: #fff;
  background: #507eff;
  border-color: #507eff;
}
.upload1{
  display:inline-block;
  margin-left:10px;
}
.upload1 .button1{background:#507eff;color:#fff}
.dialog-footer{
  text-align:center;
}
</style>
